import React from "react";
import client from "lib/ApolloClient";
import GraphqlFragment from "lib/GraphqlFragment";
import { ApolloProvider } from "react-apollo";
import gql from "graphql-tag";
import { inject, observer } from "mobx-react";
import { autobind } from "react-decoration";
import navigate from "lib/navigate";
import qs from "query-string";
import DataList from "components/list/DataList";
import EbookHistoryDisplayComp from "components/cust/EbookHistoryDisplayComp";
import EbookHistoryQueryGroupComp from "components/cust/EbookHistoryQueryGroupComp";
import JournalResourceDetailLightBox from "components/resource/JournalResourceDetailLightBox";
import EbookHistoryShareExportModal from "components/cust/EbookHistoryShareExportModal";
import Link from "lib/Link";
import ReactHtmlParser from "react-html-parser";

const getEbookHistoryList = gql`
  query getEbookHistoryList($searchForm: EbookHistoryForm) {
    getEbookHistoryList(Input: $searchForm) {
      display {
        field
        name
        position
        type
      }
      sort {
        name
        order
        field
        default
      }
      batch {
        ...getElemtn
      }
      list {
        values {
          ref {
            key
            value
          }
        }
      }
      options {
        max
        pagesize
        showpage
      }
      info {
        total
        count
        limit
        pageNo
        totalPage
        start
        hyftdToken
        userCollectionList
        sortFieldList {
          type
          message
        }
        pages {
          val
          active
        }
        showNext
        showPrevious
      }
    }
  }
  ${GraphqlFragment.defaultLayoutFragment}
`;

const getEbookHistoryDBInfo = gql`
  query getEbookHistoryDBInfo($searchForm: EbookHistoryForm) {
    getEbookHistoryDBInfo(Input: $searchForm) {
      dbID
      sourceName
      sourceEnName
    }
  }
`;

@inject("appStore")
@observer
class EbookHistoryListComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: props.readerStore.auth,
      journalID: "",
      mappingRefetch: null,
      listRefetch: null,
      total: 0,
      ids: [],
      datas: [],
      checkedData: [],
      checkedDataItem: [],
      searchUrl: "",
      breadcrumbItemList: [],
      exportModalVisible: false,
    };
  }

  componentDidMount() {
    this.genBreadcrumbItemList(this.props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (
      JSON.stringify(props.searchForm) !== JSON.stringify(this.props.searchForm)
    ) {
      this.setState({ checkedData: [], checkedDataItem: [], ids: [], datas: [] });
    }
    if (props.location.search !== this.state.searchUrl) {
      this.genBreadcrumbItemList(props);
    }
  }

  @autobind
  onQueryCompleted(data) {
    let { info, list } = data.getEbookHistoryList;
    let { total } = info;
    let { values } = list;

    let ids = [];
    let datas = [];
    values.map((value) => {
      let { ref } = value;
      let id = "";
      let tID = "";
      ref.map((v) => {
        if (v.key === "journalID") {
          id = v.value;
        } else if (v.key === "tID") {
          tID = v.value;
        }
        return "";
      });
      if (id !== "" && tID !== "") {
        ids.push(id);
        datas.push(tID);
      }
      return "";
    });

    let update = false;
    if (
      ids.length !== this.state.ids.length ||
      datas.length !== this.state.datas.length ||
      this.state.ids.length !== this.state.datas.length
    ) {
      update = true;
    } else {
      for (let i = 0; i < this.state.ids.length; i++) {
        if (this.state.ids[i] !== ids[i]) {
          update = true;
          break;
        }
        if (this.state.datas[i] !== datas[i]) {
          update = true;
          break;
        }
      }
    }

    if (update) this.setState({ ids: ids, datas: datas });

    if (total !== this.state.total) {
      this.setState({ total: total });
    }
  }

  @autobind
  changeShowFilter(type) {
    if (type) {
      this.setState({ showFilter: true });
      document.body.classList.add("fix");
    } else {
      this.setState({ showFilter: false });
      document.body.classList.remove("fix");
    }
  }

  @autobind
  changeListType(listType) {
    let { location } = this.props;
    let params = {
      pageType: "ebookHistory",
      listType: listType,
    };
    navigate([location.pathname, qs.stringify(params)].join("?"));
  }

  @autobind
  getRefetch(refetch) {
    this.setState({ listRefetch: refetch });
  }

  @autobind
  handleClick(type, item) {
    if (type === "databases") {
      /*this.setState({ dbID: item.id });
      this.dbDetailLightBox.popup();
      window.history.pushState(
        "",
        "",
        "/resourceDetail?resourceType=databases&id=" + item.id
      );*/
    } else if (type === "journal") {
      this.setState({ journalID: item.id });
      this.journalDetailLightBox.popup();
      window.history.pushState(
        "",
        "",
        "/resourceDetail?resourceType=" + item.resourceType + "&id=" + item.id
      );
    } else if (type === "addCheckedId") {
      let id = item.id;
      let c = this.state.checkedData;
      let ci = this.state.checkedDataItem;
      let tempItem = item.tID;
      if (item.checked) {
        c.push(id);
        ci.push(tempItem);
      } else {
        var index = c.indexOf(id);
        if (index !== -1) {
          c.splice(index, 1);
          ci.splice(ci.indexOf(tempItem), 1);
        }
      }

      this.setState({ checkedData: c, checkedDataItem: ci });
    }
  }

  @autobind
  async genBreadcrumbItemList(props) {
    let { search, searchForm, location, i18n } = props;
    let { language } = i18n;
    let { dbID = "" } = search;
    let theListType = searchForm.listType || "lend-current";
    let listShowType = "lendCurrent";
    if (theListType === "lend-current") {
      listShowType = "lendCurrent";
    } else if (theListType === "lend-history") {
      listShowType = "lendHistory";
    } else if (theListType === "reserve-current") {
      listShowType = "reserveCurrent";
    } else if (theListType === "reserve-history") {
      listShowType = "reserveHistory";
    }
    let breadcrumbItemList = [];
    let allParams = { ...search };
    allParams.dbID = "";
    breadcrumbItemList.push({
      message: "jumperrwd.ebookHistoryList." + listShowType + "All",
      url: [location.pathname, qs.stringify(allParams)].join("?"),
    });
    if (dbID !== "") {
      await client.jumperrwdClient
        .query({
          query: getEbookHistoryDBInfo,
          variables: {
            searchForm: {
              dbID: dbID,
            },
          },
        })
        .then(async (res) => {
          let { sourceName, sourceEnName } = res.data.getEbookHistoryDBInfo;
          let showName = sourceName;
          if (language === "en") {
            showName = sourceEnName;
          }
          let tempParams = { ...search };
          tempParams.dbID = dbID;
          breadcrumbItemList.push({
            showName: showName,
            url: [location.pathname, qs.stringify(tempParams)].join("?"),
          });
        });
    }

    this.setState({
      breadcrumbItemList,
      searchUrl: this.props.location.search,
    });
  }

  render() {
    let { searchForm, t } = this.props;
    let { showFilter, journalID, breadcrumbItemList } = this.state;
    let theListType = searchForm.listType || "lend-current";
    let listShowType = "lendCurrent";
    if (theListType === "lend-current") {
      listShowType = "lendCurrent";
    } else if (theListType === "lend-history") {
      listShowType = "lendHistory";
    } else if (theListType === "reserve-current") {
      listShowType = "reserveCurrent";
    } else if (theListType === "reserve-history") {
      listShowType = "reserveHistory";
    }
    let theDBID = searchForm.dbID || "";
    return (
      <>
        <ApolloProvider client={client.jumperrwdClient}>
          <div
            className={showFilter ? "filter_search show_up" : "filter_search"}>
            <a
              className="accesskey"
              href="javascript:;"
              id="aL"
              accessKey="L"
              title={this.props.t("jumperrwd.common.leftSideArea")}>
              :::
            </a>
            <EbookHistoryQueryGroupComp
              {...this.props}
              showFilter={showFilter}
              changeShowFilter={this.changeShowFilter}
            />
          </div>
          <div className="search_result">
            <a
              className="accesskey"
              href="javascript:;"
              id="aC"
              accessKey="C"
              title={this.props.t("jumperrwd.common.mainContenctArea")}>
              :::
            </a>
            <>
              <h2>
                {t("jumperrwd.ebookHistory." + listShowType)}
                <div className="path">
                  {breadcrumbItemList.map((breadcrumbItem, k) => {
                    let out = [];
                    let showName = "";
                    if (
                      breadcrumbItem.message &&
                      breadcrumbItem.message !== ""
                    ) {
                      showName = t(breadcrumbItem.message);
                    } else {
                      showName = breadcrumbItem.showName;
                    }
                    if (k !== 0) {
                      out.push(" > ");
                    }
                    if (k !== breadcrumbItemList.length - 1) {
                      out.push(
                        <Link href={breadcrumbItem.url}>{showName}</Link>
                      );
                    } else {
                      out.push(<>{showName}</>);
                    }
                    return out;
                  })}
                </div>
              </h2>
              <div className="tabSet">
                <ul className="tabs">
                  <li
                    className={theListType === "lend-current" ? "active" : ""}>
                    <a
                      href="javascript:;"
                      role="button"
                      onClick={(e) => {
                        e.preventDefault();
                        this.changeListType("lend-current");
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          this.changeListType("lend-current");
                        }
                      }}>
                      {t("jumperrwd.ebookHistoryList.lend.current")}
                    </a>
                  </li>
                  <li
                    className={theListType === "lend-history" ? "active" : ""}>
                    <a
                      href="javascript:;"
                      onClick={(e) => {
                        e.preventDefault();
                        this.changeListType("lend-history");
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          this.changeListType("lend-history");
                        }
                      }}>
                      {t("jumperrwd.ebookHistoryList.lend.history")}
                    </a>
                  </li>
                  <li
                    className={
                      theListType === "reserve-current" ? "active" : ""
                    }>
                    <a
                      href="javascript:;"
                      onClick={(e) => {
                        e.preventDefault();
                        this.changeListType("reserve-current");
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          this.changeListType("reserve-current");
                        }
                      }}>
                      {t("jumperrwd.ebookHistoryList.reserve.current")}
                    </a>
                  </li>
                  <li
                    className={
                      theListType === "reserve-history" ? "active" : ""
                    }>
                    <a
                      href="javascript:;"
                      onClick={(e) => {
                        e.preventDefault();
                        this.changeListType("reserve-history");
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          this.changeListType("reserve-history");
                        }
                      }}>
                      {t("jumperrwd.ebookHistoryList.reserve.history")}
                    </a>
                  </li>
                </ul>
              </div>
              <div className="function_panel">
                <div className="result_amount">
                  <input
                    type="checkbox"
                    title={t("jumperrwd.common.selectHold")}
                    disabled={this.state.ids.length === 0}
                    checked={
                      this.state.ids.length !== 0 &&
                      this.state.ids.length === this.state.checkedData.length
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        this.setState({
                          checkedData: this.state.ids,
                          checkedDataItem: this.state.datas,
                        });
                      } else
                        this.setState({ checkedData: [], checkedDataItem: [] });
                    }}
                  />
                  {t("jumperrwd.common.searchResult")}：
                  <div className="total">
                    {ReactHtmlParser(
                      t("jumperrwd.list.totalAmount", {
                        total: this.state.total,
                      })
                    )}
                  </div>
                  <div className="filter_num">
                    {ReactHtmlParser(
                      t("jumperrwd.list.checkedAmount", {
                        total: this.state.checkedData.length,
                      })
                    )}
                  </div>
                </div>
                <div className="function_block">
                  <div className="btn_grp">
                    <button
                      type="button"
                      title={t("jumperrwd.common.shareAndExport")}
                      tabIndex="0"
                      onClick={() => {
                        if (this.state.checkedData.length > 0) {
                          this.setState({ exportModalVisible: true });
                        } else {
                          alert(t("jumperrwd.cust.pleaseCheckOne"));
                        }
                      }}>
                      <i className="i_share"></i>
                      <span>{t("jumperrwd.common.shareAndExport")}</span>
                    </button>
                  </div>
                  <button
                    type="button"
                    className="filter_search_btn"
                    aria-label={this.props.t("jumperrwd.common.filterButton")}
                    onClick={this.changeShowFilter.bind(this, true)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        this.changeShowFilter(true, e);
                      }
                    }}>
                    <i className="i_menu"></i>
                  </button>
                </div>
              </div>
              <div className="list_all">
                <DataList
                  query={getEbookHistoryList}
                  variables={{
                    searchForm: {
                      listType: theListType,
                      dbID: theDBID,
                      limit: this.props.searchForm.limit,
                      pageNo: this.props.searchForm.pageNo,
                      sort: this.props.searchForm.sort,
                      order: this.props.searchForm.order,
                    },
                  }}
                  fetchPolicy={"network-only"}
                  displayComp={EbookHistoryDisplayComp}
                  pagination={"FixedPagination"}
                  onQueryCompleted={this.onQueryCompleted}
                  showNoData={true}
                  getRefetch={this.getRefetch}
                  handleClick={this.handleClick}
                  isChecked={(id) => {
                    if (this.state.checkedData.includes(id)) return true;
                    else return false;
                  }}
                />
                <EbookHistoryShareExportModal
                  {...this.props}
                  visible={this.state.exportModalVisible}
                  ids={this.state.checkedDataItem}
                  listType={theListType}
                  resourceType="ebook"
                  close={() => this.setState({ exportModalVisible: false })}
                />
              </div>
            </>
          </div>
        </ApolloProvider>
        <JournalResourceDetailLightBox
          ref={(c) => {
            this.journalDetailLightBox = c;
          }}
          id={journalID}
          resourceType="ebook"
          {...this.props}
        />
      </>
    );
  }
}

export default EbookHistoryListComp;
