import React from "react";
import PropTypes from "prop-types";
import client from "lib/ApolloClient";
import gql from "graphql-tag";
//import moment from 'moment';
import { arrangeData } from "components/list/action/action-util";
//import Link from 'next/link';
import View from "components/list/view/View";
import { autobind } from "react-decoration";
import ReactHtmlParser from "react-html-parser";
import EbookHistoryShareExportModal from "components/cust/EbookHistoryShareExportModal"

const setJournalAPIAct = gql`
  mutation setJournalAPIAct($form: JournalApiActForm) {
    setJournalAPIAct(Input: $form) {
      success
      message
      errorType
      sendURL
    }
  }
`;

class EbookHistoryDisplayComp extends View {
  constructor(props) {
    super(props);

    this.state = {
      modalVisible: false,
      platformMap: {},
      browserMap: {},
      exportModalVisible: false,
    };
  }

  componentDidMount() {}

  @autobind
  getDisplayDataByField(display = [], fieldName = "") {
    let seqIndex = display
      .map(function (display) {
        return display.field;
      })
      .indexOf(fieldName);
    return display[seqIndex] || {};
  }

  @autobind
  journalAPIAct(bookID, resourceType, name, code, type, resourceName) {
    if (this.props.readerStore.auth) {
      client.jumperrwdClient
        .mutate({
          mutation: setJournalAPIAct,
          variables: {
            form: {
              bookID: bookID,
              resourceType: resourceType,
              code: code,
              type: type,
            },
          },
        })
        .then((res) => {
          if (res.data.setJournalAPIAct.success) {
            if (
              res.data.setJournalAPIAct.sendURL &&
              res.data.setJournalAPIAct.sendURL !== ""
            ) {
              if (res.data.setJournalAPIAct.errorType === 10410) {
                alert(this.props.t("jumperrwd.journalAPIAct.alreadyLend"));
              } else if (res.data.setJournalAPIAct.errorType === 500) {
                alert(
                  this.props.t("jumperrwd.journalAPIAct." + name + "Success")
                );
              }
              if (
                this.props.refetch !== null &&
                this.props.refetch !== undefined
              ) {
                this.props.refetch();
              }
              window.open(res.data.setJournalAPIAct.sendURL, "_blank");
            } else {
              alert(
                this.props.t("jumperrwd.journalAPIAct." + name + "Success")
              );
              if (
                this.props.refetch !== null &&
                this.props.refetch !== undefined
              ) {
                this.props.refetch();
              }
            }
          } else {
            let failedCode = res.data.setJournalAPIAct.message;
            if (res.data.setJournalAPIAct.errorType === 10610) {
              alert(
                this.props.t(
                  "jumperrwd.journalAPIAct." + failedCode + "ReturnTimeFailed",
                  {
                    resourceName: resourceName,
                  }
                )
              );
            } else {
              alert(
                this.props.t(
                  "jumperrwd.journalAPIAct." + name + "Failed",
                  {
                    resourceName: resourceName,
                  }
                )
              );
            }
          }
        });
    } else {
      if (this.props.isPage) {
        this.props.appStore.openLogin();
      } else {
        this._login.wrappedComponent._openLogin();
      }
    }
  }

  render() {
    let { displayConfig, data, rowStyle, serialNo, handleClick, i18n, variables, t } =
      this.props;

    const arrangedData = arrangeData(data);

    let {
      title,
      imgUrl = "",
      freeReadCode = "",
      returnCode = "",
      bookid = "",
      journalID = "",
      tID = "",
      sourceName = "",
      sourceEnName = "",
    } = arrangedData;

    let { searchForm } = variables;
    let { listType = "lend-current" } = searchForm;

    let { language } = i18n;
    let typeList = [];
    let freeReadOut = [];

    let theSourceName =
      language === "en" && sourceName !== "" ? sourceEnName : sourceName;

    if (freeReadCode.includes(";")) {
      typeList = freeReadCode.split(";")[1].split(",");
      freeReadCode = freeReadCode.split(";")[0];
    }

    if (freeReadCode !== "") {
      if (typeList.length > 0) {
        typeList.map((type) => {
          freeReadOut.push(
            <button
              className="btn_borrow"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                this.journalAPIAct(
                  bookid,
                  "ebook",
                  "freeRead",
                  freeReadCode,
                  type,
                  theSourceName
                );
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  this.journalAPIAct(
                    bookid,
                    "ebook",
                    "freeRead",
                    freeReadCode,
                    type,
                    theSourceName
                  );
                }
              }}>
              <a>{t("jumperrwd.ebBasicApi.freeRead" + type)}</a>
            </button>
          );
          return "";
        });
      } else {
        freeReadOut.push(
          <button
            className="btn_borrow"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              this.journalAPIAct(
                bookid,
                "ebook",
                "freeRead",
                freeReadCode,
                "",
                theSourceName
              );
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                this.journalAPIAct(
                  bookid,
                  "ebook",
                  "freeRead",
                  freeReadCode,
                  "",
                  theSourceName
                );
              }
            }}>
            <a>{t("jumperrwd.ebBasicApi.freeRead")}</a>
          </button>
        );
      }
    }

    if (imgUrl === "") {
      imgUrl = "/file/images/icon_book.svg";
    }

    return (
      <>
        <div className={`list_block ${rowStyle}`}>
          <div className="list_num">
            <input
              title={t("jumperrwd.common.selectJournal")}
              type="checkbox"
              value={journalID}
              checked={this.props.isChecked(journalID)}
              onChange={(e) => {
                handleClick("addCheckedId", {
                  id: journalID,
                  tID: tID,
                  checked: e.target.checked
                });
              }}
            />
            {serialNo}
          </div>
          <div className="function_btn">
            <button
              type="button"
              tabIndex="0"
              title={t("jumperrwd.common.shareAndExport")}
              onClick={() => this.setState({ exportModalVisible: true })}>
              <i className="i_share"></i>
              <span>{t("jumperrwd.common.shareAndExport")}</span>
            </button>
          </div>
          <div className="sort_icon">
            <div className="pic">
              <img
                src={imgUrl}
                alt={t("jumperrwd.common.book")}
                onError={(e) => {
                  e.target.src = "/file/images/icon_book.svg";
                }}
              />
            </div>
          </div>
          <div className="list_data">
            <h3>
              {journalID !== "" ? (
                <a
                  tabIndex="0"
                  onClick={(e) => {
                    e.preventDefault();
                    handleClick("journal", {
                      id: journalID,
                      resourceType: "ebook",
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleClick("journal", {
                        id: journalID,
                        resourceType: "ebook",
                      });
                    }
                  }}>
                  {ReactHtmlParser(title)}
                </a>
              ) : (
                <a href="javascript:;">{ReactHtmlParser(title)}</a>
              )}
            </h3>
            <ul>
              {data.map((item, keys) => {
                if (
                  item.key !== "bookid" &&
                  item.key !== "dbID" &&
                  item.key !== "tID" &&
                  item.key !== "journalID" &&
                  item.key !== "title" &&
                  item.key !== "imgUrl" &&
                  item.key !== "freeReadCode" &&
                  item.key !== "returnCode" &&
                  !(item.key === "sourceEnName" && language !== "en") &&
                  !(item.key === "sourceName" && language === "en")
                ) {
                  let fieldConfig = this.getDisplayDataByField(
                    displayConfig,
                    item.key
                  );
                  return (
                    <li>
                      <div className="title">{fieldConfig.name}：</div>
                      <div>{item.value}</div>
                    </li>
                  );
                }
                return "";
              })}
            </ul>
            {freeReadCode !== "" && <>{freeReadOut}</>}
            {returnCode !== "" && (
              <button
                className="btn_return"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  this.journalAPIAct(
                    bookid,
                    "ebook",
                    "return",
                    returnCode,
                    "",
                    theSourceName
                  );
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    this.journalAPIAct(
                      bookid,
                      "ebook",
                      "return",
                      returnCode,
                      "",
                      theSourceName
                    );
                  }
                }}>
                <a>{t("jumperrwd.ebBasicApi.return")}</a>
              </button>
            )}
          </div>
        </div>
        <EbookHistoryShareExportModal
          {...this.props}
          visible={this.state.exportModalVisible}
          ids={[tID]}
          listType={listType}
          close={() => this.setState({ exportModalVisible: false })}
        />
      </>
    );
  }
}

EbookHistoryDisplayComp.propTypes = {
  /**
   * The layout define display config is used to define layout.
   * The datasoure is from LayoutReturnResult.display.
   */
  displayConfig: PropTypes.array.isRequired,

  /** td content */
  data: PropTypes.array,

  /** layout define's func, record link or button behavior. */
  functionConfigList: PropTypes.array,

  /** layout define's func_right, record link or button behavior. */
  functionRightConfigList: PropTypes.array,

  /** layout define's left_right, record link or button behavior. */
  functionLeftConfigList: PropTypes.array,

  /** layout define's detail */
  detailList: PropTypes.object,

  /** button events */
  buttonEvent: PropTypes.func,

  /**
   * batch config
   */
  batchConfig: PropTypes.array,

  /**
   * serialNo
   */
  serialNo: PropTypes.number,

  /**
   * checked the row or not;
   */
  checked: PropTypes.bool,

  /**
   * row style className
   */
  rowStyle: PropTypes.string,
};

EbookHistoryDisplayComp.defaultProps = {
  buttonEvent: () => {},
  functionConfigList: [],
  functionRightConfigList: [],
  functionLeftConfigList: [],
  detailList: [],
  batchConfig: [],
  serialNo: -1,
  checked: false,
  rowStyle: "",
};

export default EbookHistoryDisplayComp;
