import React from "react";
import gql from "graphql-tag";
import { withI18next } from "lib/withI18next";
import client from "lib/ApolloClient";
import { autobind } from "react-decoration";

const ebookHistoryOutput = gql`
  query ebookHistoryOutput($form: CustOutputForm) {
    ebookHistoryOutput(Input: $form) {
      success
      contenttype
      filename
      content
    }
  }
`;

const getEbookHistoryHTML = gql`
  query getEbookHistoryHTML($form: CustOutputForm) {
    getEbookHistoryHTML(Input: $form)
  }
`;

const shareEbookHistory = gql`
  mutation shareEbookHistory($form: CustOutputForm, $email: String) {
    shareEbookHistory(Input: $form, email: $email) {
      success
    }
  }
`;

@withI18next(["common"])
class EbookHistoryShareExportModal extends React.Component {
  constructor(props) {
    super(props);

    let exportOption =
      props.appStore.globalConfig["jumperrwd.ebookHistory.exportOption"];

    this.state = {
      display: props.visible ? "block" : "none",
      filename: "",
      exportHref: "",
      email: "",
      exportOption,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    //该方法内禁止访问this
    if (nextProps.visible !== prevState.visible) {
      //通过对比nextProps和prevState，返回一个用于更新状态的对象
      return {
        display: nextProps.visible ? "block" : "none",
      };
    }
    //不需要更新状态，返回null
    return null;
  }

  getExportData = (outtype) => {
    if (this.props.ids.length === 0) {
      alert(this.props.t("jumperrwd.export.pleaseSelectOne"));
      return;
    }

    client.jumperrwdClient
      .query({
        query: ebookHistoryOutput,
        variables: {
          form: {
            ids: this.props.ids,
            pageType: this.props.listType,
            resourceType: "ebook",
            pagemod: "",
            outtype: outtype,
          },
        },
      })
      .then((res) => {
        if (
          res.data.ebookHistoryOutput &&
          res.data.ebookHistoryOutput.success
        ) {
          this.setState({
            filename: res.data.ebookHistoryOutput.filename,
            exportHref: `data:${res.data.ebookHistoryOutput.contenttype};base64,${res.data.ebookHistoryOutput.content}`,
          });
          this.downloadLink.click();
        } else {
          alert(this.props.t("jumperrwd.common.exportFailed"));
        }
      });
  };

  componentDidMount() {}

  @autobind
  print(e) {
    e.preventDefault();
    if (this.props.ids.length === 0) {
      alert(this.props.t("jumperrwd.export.pleaseSelectOne"));
      return;
    }

    client.jumperrwdClient
      .query({
        query: getEbookHistoryHTML,
        variables: {
          form: {
            ids: this.props.ids,
            pageType: this.props.listType,
            resourceType: "ebook",
          },
        },
      })
      .then((res) => {
        var p = window.open("about:blank");
        setTimeout(() => {
          p.document.write(res.data.getEbookHistoryHTML);

          setTimeout(() => {
            p.print();
            p.close();
          }, 500);
        }, 500);
      });
  }

  render() {
    let { t } = this.props;
    return (
      <div
        className="personal_lightbox"
        style={{ display: this.state.display }}>
        <div className="overlay" onClick={this.props.close}></div>
        <div className="personalblock">
          <div
            className="close"
            tabIndex="0"
            onClick={this.props.close}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                this.props.close(e);
              }
            }}></div>
          <h3>{t("jumperrwd.common.shareAndExport")}</h3>
          <div className="export">
            <h4>{t("jumperrwd.common.export")}</h4>
            <ul>
              {this.state.exportOption &&
                this.state.exportOption.split(";").map((option) => {
                  switch (option) {
                    case "excel":
                      return (
                        <li>
                          <a
                            tabIndex="0"
                            title={t("jumperrwd.common.exportExcel")}
                            onClick={() => this.getExportData("1")}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                this.getExportData("1");
                              }
                            }}>
                            EXCEL
                          </a>
                        </li>
                      );
                    case "marc":
                      return (
                        <li>
                          <a
                            tabIndex="0"
                            title={t("jumperrwd.common.exportMARC")}
                            onClick={() => this.getExportData("2")}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                this.getExportData("2");
                              }
                            }}>
                            MARC
                          </a>
                        </li>
                      );
                    case "iso2709":
                      return (
                        <li>
                          <a
                            tabIndex="0"
                            title={t("jumperrwd.common.exportISO2709")}
                            onClick={() => this.getExportData("3")}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                this.getExportData("3");
                              }
                            }}>
                            ISO 2709
                          </a>
                        </li>
                      );
                    case "refwork":
                      return (
                        <li>
                          <a
                            tabIndex="0"
                            title={t("jumperrwd.common.exportRefWork")}
                            onClick={() => this.getExportData("4")}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                this.getExportData("4");
                              }
                            }}>
                            RefWork
                          </a>
                        </li>
                      );
                    case "endnote":
                      return (
                        <li>
                          <a
                            tabIndex="0"
                            title={t("jumperrwd.common.exportEndNote")}
                            onClick={() => this.getExportData("5")}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                this.getExportData("5");
                              }
                            }}>
                            EndNote
                          </a>
                        </li>
                      );
                    case "refmanager":
                      return (
                        <li>
                          <a
                            tabIndex="0"
                            title={t("jumperrwd.common.exportReference")}
                            onClick={() => this.getExportData("6")}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                this.getExportData("6");
                              }
                            }}>
                            Reference Manager
                          </a>
                        </li>
                      );
                    case "TXT":
                      return (
                        <li>
                          <a
                            tabIndex="0"
                            title={t("jumperrwd.common.exportTxt")}
                            onClick={() => this.getExportData("7")}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                this.getExportData("7");
                              }
                            }}>
                            TXT
                          </a>
                        </li>
                      );
                    case "print":
                      return (
                        <li>
                          <a
                            tabIndex="0"
                            title={t("jumperrwd.common.print")}
                            onClick={this.print.bind(this)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                this.print(e);
                              }
                            }}>
                            {t("jumperrwd.common.print")}
                          </a>
                        </li>
                      );
                    default:
                      return <></>;
                  }
                })}
            </ul>
          </div>
          <hr />
          <div className="share">
            <h4>{t("jumperrwd.common.share")}</h4>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                client.jumperrwdClient
                  .mutate({
                    mutation: shareEbookHistory,
                    variables: {
                      form: {
                        ids: this.props.ids,
                        pageType: this.props.listType,
                        resourceType: "ebook",
                      },
                      email: this.state.email,
                    },
                  })
                  .then((res) => {
                    if (res.data.shareEbookHistory.success) {
                      alert(t("jumperrwd.email.sendSuccess"));
                    } else {
                      alert(t("jumperrwd.email.sendFailed"));
                    }
                  });
              }}>
              <label htmlFor="username">{t("jumperrwd.share.mailTitle")}</label>
              <input
                type="email"
                id="username"
                placeholder={t("jumperrwd.common.enterEmail")}
                value={this.state.email}
                onChange={(e) => {
                  this.setState({ email: e.target.value });
                }}
              />
              <div className="btn_grp_right">
                <button type="submit">{t("jumperrwd.email.send")}</button>
              </div>
            </form>
          </div>
        </div>
        <a
          style={{ display: "none" }}
          ref={(c) => {
            this.downloadLink = c;
          }}
          href={this.state.exportHref}
          download={this.state.filename}>
          a
        </a>
      </div>
    );
  }
}

export default EbookHistoryShareExportModal;
