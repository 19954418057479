import React from "react";
import { Query, Mutation } from "react-apollo";
import gql from "graphql-tag";
import ReactLoading from "react-loading";
import { withI18next } from "lib/withI18next";
import cateAPI from "lib/cateAPI";

const getResourceCateList = gql`
  query getResourceCateList($sourceType: String) {
    getResourceCateList(sourceType: $sourceType) {
      allbm_count
      allunbm_count
      defSearchCount
      cate_list {
        id
        cate_name
        count
      }
    }
  }
`;

const deleteCate2SourceByID = gql`
  mutation deleteCate2SourceByID(
    $resourceType: String
    $id: String
    $cate_id: Int
  ) {
    mutation: deleteCate2SourceByID(
      resourceType: $resourceType
      id: $id
      cate_id: $cate_id
    ) {
      success
      message
    }
  }
`;

@withI18next(["common"])
class ResourceListModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      display: props.visible ? "block" : "none",
      cate: props.cate,
      ids: props.ids,
      newCate: "",
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.visible !== this.props.visible) {
      this.setState({ display: props.visible ? "block" : "none" });
    }

    if (props.cate !== this.props.cate) {
      this.setState({ cate: props.cate });
    }

    if (props.ids !== this.props.ids) {
      this.setState({ ids: props.ids });
    }
  }

  render() {
    console.info("this.props.ids", this.props.ids);
    console.info("this.state.ids", this.state.ids);
    let { t } = this.props;
    return (
      <div
        className="personal_lightbox"
        style={{ display: this.state.display }}>
        <div className="overlay" onClick={this.props.close}></div>
        <div className="personalblock">
          <div
            className="close"
            tabIndex="0"
            onClick={this.props.close}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                this.props.close(e);
              }
            }}></div>
          <h3>{t("jumper.common.addtag")}</h3>
          <Query
            query={getResourceCateList}
            variables={{
              sourceType: this.props.resourceType
                ? this.props.resourceType
                : "",
            }}
            fetchPolicy="network-only">
            {({ loading, error, data, refetch }) => {
              if (loading)
                return (
                  <div style={{ textAlign: "center" }}>
                    <ReactLoading
                      type="cylon"
                      height={"10%"}
                      width={"20%"}
                      color="#005D98"
                    />
                  </div>
                );
              if (error) return `Error!${error.message}`;

              if (data.getResourceCateList) {
                var added = [];
                var other = [];
                data.getResourceCateList.cate_list.map((c) => {
                  var add = false;
                  if (this.state.ids.length === 1) {
                    this.state.cate.split(",").map((c2) => {
                      if (c.id === c2.split("::")[0]) {
                        added.push(c);
                        add = true;
                      }
                      return "";
                    });
                  }
                  if (!add) {
                    other.push(c);
                  }
                  return "";
                });
                return (
                  <>
                    <div className="mytags added_tag">
                      <h4>{t("jumperrwd.common.addedTag")}</h4>
                      <ul>
                        {added.map((c) => {
                          return (
                            <li
                              key={
                                "addedTag_" +
                                this.props.resourceType +
                                "_" +
                                c.id
                              }>
                              {c.cate_name}
                              <span>({c.count})</span>
                              <Mutation mutation={deleteCate2SourceByID}>
                                {(deleteCate2SourceByID) => {
                                  return (
                                    <button
                                      type="button"
                                      className="del_btn"
                                      tabIndex="0"
                                      onClick={() => {
                                        deleteCate2SourceByID({
                                          variables: {
                                            resourceType:
                                              this.props.resourceType,
                                            id: this.state.ids[0],
                                            cate_id: c.id,
                                          },
                                        }).then((res) => {
                                          if (
                                            res !== null &&
                                            res !== undefined
                                          ) {
                                            if (res.data.mutation.success) {
                                              alert(
                                                t(
                                                  "jumperrwd.common.deleteSuccess"
                                                )
                                              );
                                              this.props.refetch();
                                              refetch();
                                            } else {
                                              alert(
                                                t(
                                                  "jumperrwd.common.deleteFailed"
                                                )
                                              );
                                            }
                                          }
                                        });
                                      }}>
                                      {t("jumperrwd.common.delete")}
                                    </button>
                                  );
                                }}
                              </Mutation>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="mytags other_tag">
                      <h4>{t("jumperrwd.common.otherTag")}</h4>
                      <ul>
                        {other.map((c) => {
                          return (
                            <li
                              key={
                                "otherTag_" +
                                this.props.resourceType +
                                "_" +
                                c.id
                              }>
                              {c.cate_name}
                              <span>({c.count})</span>
                              <button
                                type="button"
                                className="add_btn"
                                tabIndex="0"
                                onClick={() => {
                                  cateAPI
                                    .addListCateByCateID(
                                      this.state.ids,
                                      this.props.resourceType,
                                      c.id
                                    )
                                    .then((res) => {
                                      if (res !== null && res !== undefined) {
                                        if (res.data.mutation.success) {
                                          alert(
                                            t("jumperrwd.common.addSuccess")
                                          );

                                          // update cate to make added list
                                          let catSet = new Set();
                                          this.state.cate
                                            .split(",")
                                            .map((cate) => {
                                              catSet.add(cate);
                                              return "";
                                            });
                                          catSet.add(c.id);

                                          let cate = "";
                                          for (let item of catSet)
                                            cate += item + ",";
                                          this.setState({
                                            cate: cate.substring(
                                              0,
                                              cate.length - 1
                                            ),
                                          });

                                          this.props.refetch();
                                          refetch();
                                          //this.props.close();
                                        } else {
                                          alert(
                                            t("jumperrwd.common.addFailed")
                                          );
                                        }
                                      }
                                    });
                                }}>
                                {t("jumperrwd.common.add2")}
                              </button>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <hr />
                    <div className="newtag_input">
                      <form action="">
                        <label htmlFor="newCate">
                          {t("jumperrwd.tag.addTagName")}
                        </label>
                        <input
                          type="text"
                          id="newCate"
                          title={t("jumperrwd.common.enterTagName")}
                          placeholder={t("jumperrwd.tag.enterTagName")}
                          value={this.state.newCate}
                          onChange={(e) =>
                            this.setState({ newCate: e.target.value })
                          }
                        />
                        <div className="btn_grp_right">
                          <button
                            type="reset"
                            tabIndex="0"
                            onClick={this.props.close}>
                            {t("jumperrwd.common.cancel")}
                          </button>
                          <button
                            type="submit"
                            tabIndex="0"
                            disabled={this.state.newCate === ""}
                            onClick={(e) => {
                              e.preventDefault();
                              cateAPI
                                .addCateByIDList(
                                  this.state.ids,
                                  this.props.resourceType,
                                  this.state.newCate
                                )
                                .then((res) => {
                                  if (res !== null && res !== undefined) {
                                    if (res.data.mutation.success) {
                                      alert(t("jumperrwd.common.addSuccess"));
                                      this.props.refetch();
                                      refetch();
                                      this.props.close();
                                      this.setState({ newCate: "" });
                                    } else {
                                      alert(
                                        t("jumperrwd.common.addFailed") +
                                          " " +
                                          res.data.mutation.message
                                      );
                                    }
                                  }
                                });
                            }}>
                            {t("jumperrwd.common.add")}
                          </button>
                        </div>
                      </form>
                    </div>
                  </>
                );
              }
              return "";
            }}
          </Query>
        </div>
      </div>
    );
  }
}

export default ResourceListModal;
